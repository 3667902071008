export enum EBsButtonColor {
  "btn-primary" = "btn-primary",
  "btn-secondary" = "btn-secondary",
  "btn-success" = "btn-success",
  "btn-danger" = "btn-danger",
  "btn-warning" = "btn-warning",
  "btn-info" = "btn-info",
  "btn-light" = "btn-light",
  "btn-dark" = "btn-dark",
  "btn-outline-primary" = 'btn-outline-primary',
  "btn-outline-secondary" = 'btn-outline-secondary',
  "btn-outline-success" = 'btn-outline-success',
  "btn-outline-danger" = 'btn-outline-danger',
  "btn-outline-warning" = 'btn-outline-warning',
  "btn-outline-info" = 'btn-outline-info',
  "btn-outline-light" = 'btn-outline-light',
  "btn-outline-dark" = 'btn-outline-dark'
}
