import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BootstrapIconsModule } from 'ng-bootstrap-icons';
import { allIcons } from 'ng-bootstrap-icons/icons';
import { IconComponent } from './icon/icon.component';
import { IconBageComponent } from './icon-bage/icon-bage.component';
import { IconBageTextComponent } from './icon-bage-text/icon-bage-text.component';

@NgModule({
  declarations: [
    IconComponent,
    IconBageComponent,
    IconBageTextComponent
  ],
  imports: [
    BootstrapIconsModule.pick(allIcons),
    CommonModule
  ],
  exports: [
    BootstrapIconsModule,
    IconComponent,
    IconBageComponent,
    IconBageTextComponent
  ]
})
export class IconsModule { }

