import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'inl-modal-sceleton',
  templateUrl: './modal-sceleton.component.html',
  styleUrls: ['./modal-sceleton.component.css']
})
export class ModalSceletonComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

}
