import { EModerationStatus } from "src/app/modules/moderation/models/e-moderation-status.enum";
import { IYaGeoObjComponent } from "./i-ya-geo-obj-component";

export class Address {
  id?: string;
  userId?: string;
  fullAddress?: string;
  country?: string;
  region?: any;
  city?: string;
  street?: string;
  house?: string;
  comment?: string;
  latitude?: number;
  longitude?: number;
  moderationStatus?: EModerationStatus;
  addressPhotos?: [];
  creationDate?: Date;
  createdBy?: string;
  lastModified?: Date;
  updatedBy?: string;

  constructor(other?: Partial<Address>) {
    if (other) {
      Object.assign(this, other);
      if (other.creationDate) this.creationDate = new Date(other.creationDate);
      if (other.lastModified) this.creationDate = new Date(other.lastModified);
    }
  }

  applyYaGeo(geo: any) {
    console.log({ geo });
    const components = geo.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.Components as IYaGeoObjComponent[];
    this.country = components.find(c => c.kind === 'country')?.name;

    const provinces = components.filter(c => c.kind === 'province');
    if (provinces.length === 1) {
      this.region = provinces[0].name;
    } else if (provinces.length > 1) {
      this.region = provinces[1]?.name;
    }

    this.fullAddress = geo.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted;
    this.city = components.find(c => c.kind === 'locality')?.name;
    this.house = components.find(c => c.kind === 'house')?.name;
    this.street = components.find(c => c.kind === 'street')?.name;

    const point = geo.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ').reverse();
    this.latitude = point[0];
    this.longitude = point[1];

    console.log('formatted address', this);
  }

}
