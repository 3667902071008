import { Subject, takeUntil } from 'rxjs';
import { MessagedConfirmComponent } from './../components/messaged-confirm/messaged-confirm.component';
import { Directive, Host, Input, HostListener, EventEmitter, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[inlMessagedConfirm]'
})
export class MessagedConfirmDirective implements OnDestroy {

  @Input() inlMessagedConfirm: string = '';
  @Input() inlMessagedConfirmPre: string | undefined = '';
  @Input() inlMessagedConfirmPlaceholder: string = this._ts.instant('main.input-message');

  @Output() inlMessagedConfirmConfirmed = new EventEmitter<string>();

  private _destroyed$ = new Subject<boolean>();

  constructor(
    private _modalService: NgbModal,
    private _ts: TranslateService
  ) { }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  @HostListener('click') click() {
    const modalRef = this._modalService.open(MessagedConfirmComponent);
    modalRef.componentInstance.header = this.inlMessagedConfirm;
    modalRef.componentInstance.placeholder = this.inlMessagedConfirmPlaceholder;
    modalRef.componentInstance.preText = this.inlMessagedConfirmPre;

    (modalRef.componentInstance.close as EventEmitter<any>)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        modalRef.close();
        this._destroyed$.next(true);
      });

    (modalRef.componentInstance.confirm as EventEmitter<any>)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(text => {
        this.inlMessagedConfirmConfirmed.emit(text);
        this._destroyed$.next(true);
        modalRef.close();
      });

    (modalRef.componentInstance.cancell as EventEmitter<any>)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(text => {
        this.inlMessagedConfirmConfirmed.emit(text);
        modalRef.close();
        this._destroyed$.next(true);
      });

  }

}
