import { Injectable } from '@angular/core';
import { ToastConfig } from './../components/toasts/toasts.component';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class PopupsService {

  toasts: ToastConfig[] = [];

  constructor(
  ) { }

  toast(config: ToastConfig | string) {
    if (typeof config === 'string') {
      this.toasts.push(new ToastConfig({ text: config }));
    } else {
      this.toasts.push(config);
    }
  }
  removeToast(toast: ToastConfig) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
