export const environment = {
  production: false,
  apiUrls: {
    account: 'https://account.staging1.inlis.to',
    identity: 'https://identity.staging1.inlis.to',
    backoffice: 'https://backoffice.staging1.inlis.to',
    settings: 'https://settings.staging1.inlis.to'
  },
  yaMapKey: "b18f235b-ffa3-415d-a8f6-6877f02cf526",
  autoFillAuthData: false
};

// TODO сменить урлы на тестовые
