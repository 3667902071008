import { mergeAll, of, Subject, takeUntil } from 'rxjs';
import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[inlConfirm]'
})
export class ConfirmDirective implements OnDestroy {

  @Input() inlConfirm: string = '';
  @Input() inlConfirmHeader: string = 'Подтвердите действие';
  @Input() inlConfirmOkText: string | null = null;
  @Input() inlConfirmCancellText: string | null = null;

  @Output() inlConfirmConfirmed = new EventEmitter();

  private _destroyed = new Subject<boolean>();

  constructor(
    private _modalService: NgbModal,
    private _ts: TranslateService
  ) { }
  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  @HostListener('click') click() {
    const modalRef = this._modalService.open(ConfirmComponent);
    modalRef.componentInstance.header = this.inlConfirmHeader;
    modalRef.componentInstance.confirmText = this.inlConfirm;
    modalRef.componentInstance.okButtonText = this.inlConfirmOkText || this._ts.instant('main.ok');
    modalRef.componentInstance.cancellButtonText = this.inlConfirmCancellText || this._ts.instant('main.cancell');

    of(
      (modalRef.componentInstance.closePressed as EventEmitter<any>),
      (modalRef.componentInstance.cancellPressed as EventEmitter<any>)
    ).pipe(takeUntil(this._destroyed), mergeAll()).subscribe(() => {
      modalRef.close();
      this._destroyed.next(true);
    });

    (modalRef.componentInstance.okPressed as EventEmitter<any>)
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        modalRef.close();
        this._destroyed.next(true);
        this.inlConfirmConfirmed.emit();
      });
  }

}
