import { dictionary } from './../constants/dictionary';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staticDict'
})
export class StaticDictPipe implements PipeTransform {

  transform(id: number | undefined, dictField: keyof typeof dictionary): any {
    if (id === undefined) return null;
    const dict = dictionary[dictField];
    return dict.find(d => d.id === id);
  }

}
