export function isPrimitive(value: any): boolean {

  if (value === null) {
    return true;
  }

  if (typeof value == "object" || typeof value == "function") {
    return false;
  } else {
    return true;
  }
}
