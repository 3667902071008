import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '../components/inl-form-address/models/address';
import { SelectAddressModalComponent } from '../components/select-address-modal/select-address-modal.component';

@Directive({
  selector: '[inlSelectAddress]'
})
export class SelectAddressDirective {

  @Input() inlSelectAddress?: Address;
  @Output() inlSelectAddressSelected = new EventEmitter<Address>();

  modalRef?: NgbModalRef;

  constructor(
    private _bsModal: NgbModal
  ) { }

  @HostListener('click') onClick() {
    this.modalRef = this._bsModal.open(SelectAddressModalComponent, { size: 'lg' });
    this.modalRef.componentInstance.address = new Address(this.inlSelectAddress);
    this.modalRef.closed.pipe().subscribe(() => {
      if (this.modalRef?.componentInstance.hasAddress) {
        this.inlSelectAddressSelected.emit(this.modalRef?.componentInstance.addressControl.value);
      }
    });
  }

}
