<inl-modal-sceleton (close)="close.emit()">
  <div header>{{header}}</div>
  <div body>
    <inl-form-input [placeholder]="placeholder" inputType="text-area" [formControl]="textControl"></inl-form-input>
  </div>
  <div footer>
    <inl-button class="me-2" (inlClick)="close.emit()" colorClass="btn-danger">{{cancellButtonText}}</inl-button>
    <inl-button [disabled]="textControl.invalid || textControl.pristine"
      (inlClick)="confirm.emit(textControl.value)">{{okButtonText}}</inl-button>
  </div>
</inl-modal-sceleton>
