import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldSort'
})
export class FieldSortPipe implements PipeTransform {

  transform(value: any[] | undefined, field: string, ask: boolean = false): any[] {
    if (!value) return [];
    return value.sort((v0, v1) => {
      if (v0[field] > v1[field]) return ask ? -1 : 1;
      if (v0[field] < v1[field]) return ask ? 1 : -1;
      return 0;
    });
  }

}
