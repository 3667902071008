export const dictionary = {
  mainActivities: [
    { id: 1, text: 'dictionary.mainActivities.BEAUTY' },
  ],
  activities: [
    { id: 1, text: 'dictionary.activities.MAKEUP' },
    { id: 2, text: 'dictionary.activities.HAIRCUTS' },
    { id: 3, text: 'dictionary.activities.EPILATION' },
    { id: 4, text: 'dictionary.activities.MANICURE-PEDICURE' },
    { id: 5, text: 'dictionary.activities.TATTOOING' },
    { id: 6, text: 'dictionary.activities.EYELASH-EXT' },
    { id: 7, text: 'dictionary.activities.TATTOO' },
    { id: 8, text: 'dictionary.activities.EYEBROW-DESIGN' },
    { id: 9, text: 'dictionary.activities.PIERCING' }
  ],
  services: [
    { id: 1, activityId: 1, text: 'dictionary.services.HOLIDAY-MAKEUP' },
    { id: 2, activityId: 1, text: 'dictionary.services.OUT-MAKEUP' },
    { id: 3, activityId: 1, text: 'dictionary.services.CHILDREN-MAKEUP' },
    { id: 4, activityId: 1, text: 'dictionary.services.PAINT-MAKEUP' },
    { id: 5, activityId: 1, text: 'dictionary.services.PHOTO-MAKEUP' },
    { id: 6, activityId: 1, text: 'dictionary.services.SMOKY-EYES' },
    { id: 7, activityId: 1, text: 'dictionary.services.EXPRESS-MAKEUP' },
    { id: 11, activityId: 2, text: 'dictionary.services.COATED-MAKEUP' },
    { id: 12, activityId: 2, text: 'dictionary.services.COATED-PEDICURE' },
    { id: 13, activityId: 2, text: 'dictionary.services.EXTENSION' },
    { id: 14, activityId: 2, text: 'dictionary.services.DESIGN' },
    { id: 15, activityId: 2, text: 'dictionary.services.NAIL-CORRECTION' },
    { id: 21, activityId: 3, text: 'dictionary.services.WOMAN-HAIRCUT' },
    { id: 22, activityId: 3, text: 'dictionary.services.MAN-HAIRCUT' },
    { id: 23, activityId: 3, text: 'dictionary.services.COLORING' },
    { id: 24, activityId: 3, text: 'dictionary.services.EXTREMAL-COLORING' },
    { id: 25, activityId: 3, text: 'dictionary.services.PERM' },
    { id: 26, activityId: 3, text: 'dictionary.services.HOLIDAY-HAIRCUT' },
    { id: 27, activityId: 3, text: 'dictionary.services.WEDDING-HAIRCUT' },
    { id: 31, activityId: 4, text: 'dictionary.services.MAN-EPILATION' },
    { id: 32, activityId: 4, text: 'dictionary.services.WOMAN-EPILATION' },
    { id: 33, activityId: 4, text: 'dictionary.services.FACE-EPILATION' },
    { id: 34, activityId: 4, text: 'dictionary.services.HAND-EPILATION' },
    { id: 35, activityId: 4, text: 'dictionary.services.LEG-EPILATION' },
    { id: 36, activityId: 4, text: 'dictionary.services.BIKINI-EPILATION' },
    { id: 37, activityId: 4, text: 'dictionary.services.INTIMATE-COLORING' },
    { id: 41, activityId: 5, text: 'dictionary.services.VOLUME-EXTENSION' },
    { id: 42, activityId: 5, text: 'dictionary.services.CLASSIC-EXTENSION' },
    { id: 43, activityId: 5, text: 'dictionary.services.EYELASH-DECOR' },
    { id: 44, activityId: 5, text: 'dictionary.services.COLORED-EXTENSION' },
    { id: 45, activityId: 5, text: 'dictionary.services.CORNER-EXTENSION' },
    { id: 46, activityId: 5, text: 'dictionary.services.CORRECTION' },
    { id: 47, activityId: 5, text: 'dictionary.services.LAMINATION' },
    { id: 51, activityId: 6, text: 'dictionary.services.EYEBROW-CORRECTION' },
    { id: 52, activityId: 6, text: 'dictionary.services.EYEBROW-COLORING' },
    { id: 53, activityId: 6, text: 'dictionary.services.EYEBROW-LAMINATION' },
    { id: 61, activityId: 7, text: 'dictionary.services.LIP-TATTOO' },
    { id: 62, activityId: 7, text: 'dictionary.services.EYEBROW-TATTOO' },
    { id: 63, activityId: 7, text: 'dictionary.services.INTERCILIARY-TATTOO' },
    { id: 64, activityId: 7, text: 'dictionary.services.TATTOO-CORRECTION' },
    { id: 71, activityId: 8, text: 'dictionary.services.EYEBROW-PIERCING' },
    { id: 72, activityId: 8, text: 'dictionary.services.TONGUE-PIERCING' },
    { id: 73, activityId: 8, text: 'dictionary.services.EAR-PIERCING' },
    { id: 74, activityId: 8, text: 'dictionary.services.INTIMATE-PIERCING' },
    { id: 75, activityId: 8, text: 'dictionary.services.PIERCING-REMOVAL' },
    { id: 81, activityId: 9, text: 'dictionary.services.ARM-TATTOO' },
    { id: 82, activityId: 9, text: 'dictionary.services.LEG-TATTOO' },
    { id: 83, activityId: 9, text: 'dictionary.services.STOMACH-TATTOO' },
    { id: 84, activityId: 9, text: 'dictionary.services.BACK-TATTOO' },
    { id: 85, activityId: 9, text: 'dictionary.services.ART-TATTOO' },
    { id: 86, activityId: 9, text: 'dictionary.services.TATTOO-REMOVAL' },
    { id: 87, activityId: 9, text: 'dictionary.services.TATTOO-COVERING' }
  ],
  maxFutureId: [
    { id: 2, text: 'dictionary.max-future.WEEK' },
    { id: 4, text: 'dictionary.max-future.2-WEEK' },
    { id: 8, text: 'dictionary.max-future.CURRENT-MONTH' },
    { id: 16, text: 'dictionary.max-future.MONTH' },
    { id: 32, text: 'dictionary.max-future.2-MONTH' },
    { id: 64, text: 'dictionary.max-future.6-MONTH' }
  ],
  intervalId: [
    { id: 2, text: 'dictionary.interval.15' },
    { id: 4, text: 'dictionary.interval.30' },
    { id: 8, text: 'dictionary.interval.45' },
    { id: 16, text: 'dictionary.interval.60' },
    { id: 32, text: 'dictionary.interval.90' },
    { id: 64, text: 'dictionary.interval.2' },
    { id: 128, text: 'dictionary.interval.3' },
    { id: 256, text: 'dictionary.interval.6' }
  ],
};
