import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { of, tap, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _tokenLSKey = 'Token';
  private _refreshTokenLSKey = 'RefreshToken';

  redirectUrl: string | null = null;
  token$ = new BehaviorSubject<string>('');

  constructor(
    private _http: HttpClient,
    private _ls: LocalStorageService,
    private _router: Router
  ) {
    this.token$.next(this._ls.getItem(this._tokenLSKey));
  }

  login$(username: string, password: string): Observable<IAuthData> {
    const params = new HttpParams({
      fromObject: {
        password,
        username,
        grant_type: 'password',
        client_id: 'client-id-code',
        client_secret: 'client-secret-code'
      },
    });
    const httpOptions = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this._http.post<IAuthData>(`${environment.apiUrls.identity}/connect/token`, params.toString(), httpOptions)
      .pipe(
        tap(data => {
          this._ls.setItem(this._tokenLSKey, data.access_token);
          this._ls.setItem(this._refreshTokenLSKey, data.refresh_token);
          console.log(jwt_decode(data.access_token));
          this.token$.next(data.access_token);
        })
      );
  }

  refresh$(): Observable<IAuthData> {
    const params = new HttpParams({
      fromObject: {
        refresh_token: this._ls.getItem(this._refreshTokenLSKey),
        grant_type: 'refresh_token',
        client_id: 'client-id-code',
        client_secret: 'client-secret-code'
      }
    });
    const httpOptions = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };
    return this._http.post<IAuthData>(`${environment.apiUrls.identity}/connect/token`, params.toString(), httpOptions).pipe(
      tap(data => {
        this._ls.setItem(this._tokenLSKey, data.access_token);
        this._ls.setItem(this._refreshTokenLSKey, data.refresh_token);
        this.token$.next(data.access_token);
      })
    );
  }

  logout(redirectUrl: string | null = null) {
    console.log({ redirectUrl });
    this.redirectUrl = redirectUrl;
    this._ls.removeItem(this._tokenLSKey);
    this._ls.removeItem(this._refreshTokenLSKey);
    this.token$.next('');
    this._router.navigate(['auth']);
  }

}

interface IAuthData {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}
