<label *ngIf="label" class="form-label">
  {{label}}
</label>
<div class="mb-3 input-group">
  <ng-content select="inl-form-input-front"></ng-content>

  <input [placeholder]="placeholder" *ngIf="inputType === 'input'" class="form-control" [type]="type" [ngClass]="{
    'is-invalid':formControl.invalid && formControl.touched
  }" [formControl]="formControl">

  <textarea *ngIf="inputType === 'text-area'" [placeholder]="placeholder" cdkTextareaAutosize
    #autosize="cdkTextareaAutosize" [cdkAutosizeMinRows]="minRows" [cdkAutosizeMaxRows]="maxRows" class="form-control"
    [ngClass]="{
    'is-invalid':formControl.invalid && formControl.touched
  }" [formControl]="formControl"></textarea>

  <ng-container *ngIf="indicateError">
    <div *ngFor="let error of formControl.errors|keyvalue" class="invalid-feedback">
      {{'validators.' + error.key|translate}}
    </div>
  </ng-container>
</div>
