import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { AuthGuardService } from './guards/auth-guard.service';

const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: 'private', loadChildren: () => import('./modules/private/private.module').then(m => m.PrivateModule), canActivate: [AuthGuardService] },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

