import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'inl-form-input-front',
  templateUrl: './form-input-front.component.html',
  styleUrls: ['./form-input-front.component.css']
})
export class FormInputFrontComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
