<div class="loader-container">
  <div class="overlay"></div>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
    </div>
    <div *ngIf="text">
      <h4 class="text-primary">
        {{text}}
      </h4>
    </div>
  </div>
</div>
