import { Component, Input, OnInit } from '@angular/core';
import { BsTextColors } from 'src/app/types/bs-text-colors';

@Component({
  selector: 'inl-spinner',
  templateUrl: './inl-spinner.component.html',
  styleUrls: ['./inl-spinner.component.css']
})
export class InlSpinnerComponent implements OnInit {

  @Input() size: 'spinner-border-sm' | '' = '';
  @Input() color: BsTextColors = 'text-primary';

  constructor() { }

  ngOnInit() {
  }

}
