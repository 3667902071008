import { BsBgCorols } from './../../../../types/bs-bg-corols';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsTextColors } from 'src/app/types/bs-text-colors';

@Component({
  selector: 'inl-icon-button',
  templateUrl: './inl-icon-button.component.html',
  styleUrls: ['./inl-icon-button.component.css']
})
export class InlIconButtonComponent implements OnInit {

  @Output() inlClick = new EventEmitter<boolean>();

  @Input() disabled = false;
  @Input() loading = false;
  @Input() loadingColor: BsTextColors = 'text-primary';
  @Input() color: BsTextColors = 'text-primary';
  @Input() iconName: string = '';
  @Input() bgColor: BsBgCorols | 'noClass' = 'noClass';

  disabledColor: BsTextColors = 'text-muted';

  constructor() { }

  ngOnInit() {
  }

}
