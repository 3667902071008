import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[openProfile]'
})
export class OpenProfileDirective {

  @Input() openProfile?: string;

  constructor(
    private _router: Router
  ) { }

  @HostListener('click') click() {
    window.open(`private/search/profile/${this.openProfile}`, '_blank')
  }

}
