import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Address } from '../models/address';
import { HttpClient } from '@angular/common/http';
import { YaSuggestGeoObj } from '../models/ya-suggest-geo-obj';

@Injectable({
  providedIn: 'root'
})
export class YaMapService {

  suggests$ = new BehaviorSubject<YaSuggestGeoObj[]>([]);

  private _mapKey = '';

  private _scriptInited = false;

  constructor(
    private _httpClient: HttpClient
  ) { }

  private _initMapKey$(): Observable<string> {
    this._mapKey = environment.yaMapKey;
    return of(this._mapKey);
  }

  initMap$(): Observable<any> {
    return this._initMapKey$().pipe(
      switchMap(mapKey => {
        return new Observable<any>(subscriber => {
          subscriber.next();
          subscriber.complete();
          if (this._scriptInited) {
            subscriber.next();
            subscriber.complete();
          } else {
            const mapEl = document.createElement('script');
            mapEl.type = 'text/javascript';
            mapEl.src = `https://api-maps.yandex.ru/2.1/?apikey=${mapKey}&lang=ru_RU`;
            document.getElementsByTagName('head')[0].appendChild(mapEl);
            mapEl.onload = () => {
              (window as { [key: string]: any })['ymaps'].ready(
                () => {
                  this._scriptInited = true;
                  subscriber.next();
                  subscriber.complete();
                }
              );
            };
          }
        });
      })
    );
  }

  updateSuggests(address: string, take = 10) {
    if (this._scriptInited) {
      (window as { [key: string]: any })['ymaps'].suggest(address, { results: take }).then((items: YaSuggestGeoObj[]) => {
        this.suggests$.next(items);
      });
    }
  }

  updateSuggests$(address: string, take = 10): Observable<YaSuggestGeoObj[]> {
    return new Observable<YaSuggestGeoObj[]>(subscriber => {
      if (this._scriptInited) {
        (window as { [key: string]: any })['ymaps'].suggest(address, { results: take }).then((items: YaSuggestGeoObj[]) => {
          this.suggests$.next(items);
          subscriber.next(items);
          subscriber.complete();
        });
      } else {
        subscriber.next([]);
        subscriber.complete();
      }
    });
  }

  getGeoObject$(address: string): Observable<any> {
    return this._httpClient.get<any>(
      `https://geocode-maps.yandex.ru/1.x/?apikey=${this._mapKey}&geocode=${encodeURI(address)}&format=json`
    ).pipe(
    );
  }

}
