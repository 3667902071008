<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <ng-content select="[header]"></ng-content>
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close.emit(true)"></button>
</div>
<div class="modal-body">
  <ng-content select="[body]"></ng-content>
</div>
<div class="modal-footer">
  <ng-content select="[footer]"></ng-content>
</div>
