import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ToastConfig } from '../components/toasts/toasts.component';
import { PopupsService } from '../services/popups.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptorsService implements HttpInterceptor {

  constructor(
    private _popups: PopupsService,
    private readonly injector: Injector
  ) { }

  private _getErrorText(e: any): string {

    const _ts = this.injector.get(TranslateService);
    if (e.status === 403) {
      return _ts.instant('errors.no-rights', { message: e.message });
    } else {
      return e?.message || 'error';
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(e => {
      this._popups.toast(new ToastConfig({ color: 'bg-danger text-light', text: this._getErrorText(e) }));
      return throwError(() => e);
    }));
  }

}
