import { PopupsService } from './../../services/popups.service';
import { Component, OnInit } from '@angular/core';

export class ToastConfig {
  color: 'bg-danger text-light' | 'bg-success text-light' | 'bg-warning' | null = null;
  text: string = '';
  delay: number = 9000;
  constructor(other: Partial<ToastConfig>) {
    Object.assign(this, other);
  }
}

@Component({
  selector: 'inl-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.css'],
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsComponent implements OnInit {

  constructor(
    public popups: PopupsService
  ) { }

  ngOnInit() {
  }
}
