import { Component, OnInit, Input } from '@angular/core';
import { BsBgCorols } from 'src/app/types/bs-bg-corols';

@Component({
  selector: 'inl-icon-bage',
  templateUrl: './icon-bage.component.html',
  styleUrls: ['./icon-bage.component.css']
})
export class IconBageComponent implements OnInit {

  @Input() bgColor: BsBgCorols = 'bg-warning';
  @Input() isIcon = true;

  constructor() { }

  ngOnInit() {
  }

}
