import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StaticDictPipe } from './static-dict.pipe';
import { FieldSortPipe } from './field-sort.pipe';
import { SortWithComparatorPipe } from './sort-with-comparator.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    StaticDictPipe,
    FieldSortPipe,
    SortWithComparatorPipe
  ],
  exports: [
    StaticDictPipe,
    FieldSortPipe,
    SortWithComparatorPipe
  ]
})
export class PipesModule { }
